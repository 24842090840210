import React from 'react'
import Iframe from 'react-iframe'

import Layout from '../components/layout'

export default function ContactUs() {
  return (
    <Layout>
      <div className="contact-us">
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="columns twelve">
                <h1 className="center-text">Get in Touch</h1>
                <p>Introduction to get in touch </p>
                <p>lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: '80px' }}>
          <div className="row">
            <div className="six columns contact-details">
              <p className="bold-subtitle">Say Hello</p>

              <p>Call us: 09349 934 923</p>
              <p>Email us: lorem@ipsum.com</p>

              <p className="bold-subtitle">Finding Us</p>

              <p>22-28 Clough Rd </p>
              <p>Rotherham </p>
              <p>South Yorkshire</p>
              <p>S61 1RD</p>
            </div>
            <div className="six columns">
              <Iframe
                url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9508.19970045386!2d-1.3723985!3d53.4318156!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x54bf141e37632cba!2sJohn%20Ashley%20Classic%20And%20Sports%20Car%20Specialists!5e0!3m2!1sen!2suk!4v1571405497171!5m2!1sen!2suk"
                width="100%"
                height="450px"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
